import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { ContentContainer, Post, Seo, Title, Image } from "../components"

const singlePost = ({ data }) => {
  // const featureImage = data.mdx.frontmatter.featureImage.childImageSharp.fixed
  // const seoImage = data.mdx.frontmatter.featureImage.publicURL
  return (
    <ContentContainer>
      <Seo
        title={data.mdx.frontmatter.title}
        // image={seoImage}
        description={data.mdx.frontmatter.description}
      />
      <div className="post-image">
        <Image fluid={data.mdx.frontmatter.image.childImageSharp.fluid}></Image>
      </div>
      <Post>
        <Title>{data.mdx.frontmatter.title}</Title>
        <p className="post-date">Last Updated: {data.mdx.frontmatter.date}</p>
        <div className="post-content">
          <MDXRenderer>{data.mdx.body}</MDXRenderer>
        </div>
      </Post>
    </ContentContainer>
  )
}

export default singlePost

export const pageQuery = graphql`
  query singlePostQuery($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        slug
        description
        date(formatString: "DD MMMM YYYY")
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

//   query singlePostQuery($slug: String!) {
//     mdx(frontmatter: { slug: { eq: $slug } }) {
//       body
//       frontmatter {
//         date
//         excerpt
//         slug
//         title
//         featureImage {
//           publicURL
//           childImageSharp {
//             fixed {
//               ...GatsbyImageSharpFixed
//             }
//           }
//         }
//       }
//     }
//   }
// `
